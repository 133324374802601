<template>
  <section v-if="loaded && gameCount">
    <transition name="fade">
      <div class="loading" v-if="!banner">
        <!--img src="@/assets/img/loading.gif" /-->
      </div>
    </transition>
    <!--button class="dropdown" :value="categoryName" @click="aselect=!aselect">{{ categoryName }}</button-->
    <div class="aselect">
       <ul :class="{'active': currentTab == ''}">
         <li :class="{'on': currentTab == 'tab-0', 'off': currentTab != 'tab-0'}" data-tab="tab-0"
          @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-0')">
           <a class="gamem">
             <span class="ihome">홈</span>
           </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-2')"
            v-if="gameCount['casino']"
            :class="{'on': currentTab == 'tab-2', 'off': currentTab != 'tab-2'}"
            data-tab="tab-2">
            <a class="gamem">
               <span class="icasino">{{$t('front.gnb.livecasino')}}</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gnb.hotelcasino'), 'tab-3')"
            v-if="gameCount['hc-casino']"
            :class="{'on': currentTab == 'tab-3', 'off': currentTab != 'tab-3'}"
            data-tab="tab-3">
            <a class="gamem">
               <span class="ihcasino">호텔카지노</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.slot'), 'tab-4')"
            v-if="gameCount['slot']"
            :class="{'on': currentTab == 'tab-4', 'off': currentTab != 'tab-4'}"
            data-tab="tab-4">
            <a class="gamem">
               <span class="islot">{{$t('front.gnb.slot')}}</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.sport'), 'tab-5')"
            v-if="gameCount['sports']"
            :class="{'on': currentTab == 'tab-5', 'off': currentTab != 'tab-5'}"
            data-tab="tab-5">
            <a class="gamem">
               <span class="isport">{{$t('front.gnb.sport')}}</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.minigame'), 'tab-6')"
            v-if="gameCount['mini-game']"
            :class="{'on': currentTab == 'tab-6', 'off': currentTab != 'tab-6'}"
            data-tab="tab-6">
            <a class="gamem">
               <span class="imini">{{$t('front.gnb.minigame')}}</span>
            </a>
         </li>
       </ul>
    </div>
    <!--transition name="fade">
      <ui-swiper v-if="banner"/>
    </transition>
    <div id="mainobj">
      <div class="obj1 obj"><img src="../assets/img/main/greatestbenefit.png"></div>
      <div class="obj2 obj"><img src="../assets/img/main/jackpot.png"></div>
      <div class="obj3 obj objani"><img src="../assets/img/main/crown.png"></div>
      <div class="obj4 obj objani"><img src="../assets/img/main/cherry.png"></div>
      <div class="obj5 obj objani"><img src="../assets/img/main/spade.png"></div>
      <div class="obj6 obj objani"><img src="../assets/img/main/clover2.png"></div>
      <div class="obj7 obj objani"><img src="../assets/img/main/seven.png"></div>
      <div class="obj8 obj objani"><img src="../assets/img/main/coin.png"></div>
      <div class="obj9 obj objani"><img src="../assets/img/main/diamond.png"></div>
    </div>
    <div class="scrNavi">
      <ul class="navi tabs">
        <--li @click="currentTab = 'tab-1'" :class="{'on': currentTab == 'tab-1'}" data-tab="tab-1"><em class="all"></em><span>{{$t('front.gameCategory.all')}}</span></li->
        <li @click="currentTab = 'tab-2'" v-if="gameCount['casino']" :class="{'on': currentTab == 'tab-2'}" data-tab="tab-2"><a><em class="live"></em><span>{{$t('front.gameCategory.livecasino')}}</span></a></li>
        <li @click="currentTab = 'tab-3'" v-if="gameCount['hc-casino']" :class="{'on': currentTab == 'tab-3'}" data-tab="tab-3"><a><em class="hotel"></em><span>{{$t('front.gnb.hotelcasino')}}</span></a></li>
        <li @click="currentTab = 'tab-4'" v-if="gameCount['slot']" :class="{'on': currentTab == 'tab-4'}" data-tab="tab-4"><a><em class="slot"></em><span>{{$t('front.gameCategory.slot')}}</span></a></li>
        <li @click="currentTab = 'tab-7'" v-if="gameCount['h-slot']" :class="{'on': currentTab == 'tab-7'}" data-tab="tab-7"><a><em class="hotelslot"></em><span>{{$t('front.gnb.hotelslot')}}</span></a></li>
        <li @click="currentTab = 'tab-5'" v-if="gameCount['sport']" :class="{'on': currentTab == 'tab-5'}" data-tab="tab-5"><a><em class="sport"></em><span>{{$t('front.gnb.sport')}}</span></a></li>
        <li @click="currentTab = 'tab-6'" v-if="gameCount['minigame']" :class="{'on': currentTab == 'tab-6'}" data-tab="tab-6"><a><em class="mini"></em><span>{{$t('front.gnb.minigame')}}</span></a></li>
      </ul>
    </div-->
   <div v-if="currentTab == 'tab-0'">
      <section class="mbanner">
         <img src="@/assets_mobile/img/mban.png" />
         <ul>
            <li class="txt1">안녕하세요. 코코에 오신것을 환영합니다!</li>
            <li class="txt2">LIVE DEALERS SHOWER YOU WITH</li>
            <li class="txt3">WELCOME BONUS <em class="gn">100%</em></li>
         </ul>
      </section>
      <section>
         <ul class="gametab">
            <li>
               <h2><img src="@/assets_mobile/img/icon_m_casino.svg" />{{$t('front.gnb.livecasino')}}</h2>
               <span><em>생생한 HD 라이브로 즐기는 미녀 딜러와 스릴 넘치는 카지노 모험을 경험 하세요.</em></span>
               <a @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-2')">라이브 카지노로 이동</a>
            </li>
            <!-- <li>
               <h2><img src="@/assets_mobile/img/icon_m_hotel.svg" />{{$t('front.gnb.hotelcasino')}}</h2>
               <span><em>럭셔리한 당신을 위한 선택! 프리미엄 호텔 카지노에서 짜릿한 카지노 모험을 경험하세요.</em></span>
               <a @click="selectCategory($t('front.gnb.hotelcasino'), 'tab-3')">호텔 카지노로 이동</a>
            </li> -->
            <li>
               <h2><img src="@/assets_mobile/img/icon_m_slot.svg" />{{$t('front.gameCategory.slot')}}</h2>
               <span><em>수백개 이상 슬롯 게임 보유! 다양한 슬롯 게임속 세계를 탐험하고 베팅할 수 있습니다.</em></span>
               <a @click="selectCategory($t('front.gameCategory.slot'), 'tab-4')">슬롯게임으로 이동</a>
            </li>
            <!-- <li>
               <h2><img src="@/assets_mobile/img/icon_m_sport.svg" />{{$t('front.gnb.sport')}}</h2>
               <span><em>다양한 스포츠 게임 보유! 다양한 스포츠 게임속 세계를 탐험하고 베팅할 수 있습니다.</em></span>
               <a @click="selectCategory($t('front.gameCategory.sport'), 'tab-5')">스포츠게임으로 이동</a>
            </li> -->
            <!-- <li>
               <h2><img src="@/assets_mobile/img/icon_m_mini.svg" />{{$t('front.gnb.minigame')}}</h2>
               <span><em>다양한 미니게임 보유! 다양한 미니게임속 세계를 탐험하고 베팅할 수 있습니다.</em></span>
               <a @click="selectCategory($t('front.gameCategory.minigame'), 'tab-6')">미니게임으로 이동</a>
            </li> -->
         </ul>
      </section>
      <section>
        <h2 class="mainnames">
           <img src="@/assets_mobile/img/icon_m_notice.svg" />
           {{ $t('front.common.notice') }}
           <a class="more" @click="goPageByName('noticeList')"><img src="@/assets_mobile/img/icon_m_arrow.svg" /></a>
        </h2>
        <div class="servicecenter">
         <ul @click="goPageByName('noticeList')">
            <template v-for="item in noticeList" :key="item">
             <li>
                <i><img src="@/assets_mobile/img/icon_bell.svg" /></i>
                <span><em>{{item.regDate}}</em>{{item.title}}</span>
             </li>
            </template>
            <template v-for="item in noticeListMaxCount - noticeList.length" :key="item">
             <li>
                <span><em>&nbsp;</em>&nbsp;</span>
             </li>
            </template>
         </ul>
        </div>
      </section>
      <!-- <section>
         <h2 class="mainnames">
            <img src="@/assets_mobile/img/icon_m_inout.svg" />
            {{ $t('front.main.livecharge') }}
         </h2>
         <div class="inoutwrap">
            <div class="btn">
               <a @click="onChangeType('in')" :class="{'active': type === 'in'}">{{ $t('front.main.livecharge') }}</a>
               <a @click="onChangeType('out')" :class="{'active': type === 'out'}">{{ $t('front.main.liveExchange') }}</a>
            </div>
            <div v-if="type == 'in'" class="inout">
               <ul>
                 <template v-for="item in cashInList" :key="item.memId">
                   <li>
                     <span><img src="@/assets/img/icon_inin.svg" />{{item.memId}}</span>
                     <span>{{thousand(Number(item.cashAmt))}} 원</span>
                     <span>{{item.regDate}}</span>
                   </li>
                 </template>
                 <template v-for="item in listMaxCount - cashInList.length" :key="item">
                 </template>
               </ul>
            </div>
            <div v-if="type == 'out'" class="inout">
               <ul>
                 <template v-for="item in cashOutList" :key="item.memId">
                   <li>
                     <span><img src="@/assets/img/icon_outout.svg" />{{item.memId}}</span>
                     <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                     <span>{{item.regDate}}</span>
                   </li>
                 </template>
                 <template v-for="item in listMaxCount - cashOutList.length" :key="item">
                 </template>
               </ul>
            </div>
         </div>
      </section> -->
      <section>
         <h2 class="mainnames">
            <img src="@/assets_mobile/img/icon_m_sns.svg" />
            {{ $t('front.mypage.community') }}
         </h2>
         <ul class="sns" v-if="siteOption">
           <li>
              <a @click="siteOption['sns2Link']">
                 <img src="@/assets/img/icon_kakao.svg" />
                 <span><em>{{siteOption['sns2Id']}}</em></span>
              </a>
           </li>
           <li>
              <a @click="siteOption['sns1Link']">
                 <img src="@/assets/img/telegram.svg" />
                 <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
              </a>
           </li>
           <li v-if="siteOption['sns3']">
              <a @click="siteOption['sns3Link']">
                 <img src="@/assets/img/zalo.svg" />
                 <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
              </a>
           </li>
         </ul>
      </section>
   </div>
   <div class="tab-content" v-bind:key="currentTab">
     <ul class="gamewrap">
       <template v-for="game in gameList" v-bind:key="game.code">
         <li v-if="currentTab == 'tab-1' || (currentTab != 'tab-1' && currentTab==game.type)"
             :class="game.type">
           <img :src="getBackgroundImage(game)" />
           <p class="name">
             <span>
                {{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}
                <em :class="game.groupCode">
                  <template v-if="game.groupCode === 'casino'">
                    <template v-if="game.groupCodeName.indexOf('HC-') === -1">
                      {{ $t('front.gnb.livecasino') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelcasino') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'slot'">
                    <template v-if="game.groupCodeName.indexOf('H-') === -1">
                      {{ $t('front.gnb.slot') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelslot') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'minigame'">
                    {{ $t('front.gnb.minigame') }}
                  </template>
                  <template v-if="game.groupCode === 'sport'">
                    {{ $t('front.gnb.sport') }}
                  </template>
                </em>
             </span>
             <i>게임시작</i>
           </p>
           <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
           <template v-if="game.groupCode == 'slot'">
             <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
           </template>

           <template v-else-if="game.groupCode == 'new'">
             <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
           </template>
           <template v-else>
             <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
           </template>
         </li>
       </template>
     </ul>
   </div>

  </section>
  <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
    <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
      <div class="closeWrap">
        <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
        <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
      </div>
      <img :src="item.bannerImg" v-if="item.bannerImg" />
        <div class="txtwrap">
          <h3>{{item.bannerTitle}}</h3>
          <ul class="popupTxt" v-if="item.bannerContent">
            <li v-html="item.bannerContent">
            </li>
          </ul>
        </div>
    </div>
  </template>

</template>

<script>

import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getSlotGame } from '@/api/game'
import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'MIndex',
  components: {
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'commonCodeByCode',
      'popup',
      'popupLogin',
      'banner',
      'gameCount',
      'siteOption'
    ])
  },
  data () {
    return {
      type: 'in',
      categoryName: this.$t('front.gameCategory.livecasino'),
      loaded: false,
      currentTab: 'tab-0',
      gameList: [],
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      popupList: {},
      listMaxCount: 3,
      noticeListMaxCount: 5
    }
  },
  watch: {
    async commonCodeByCode () {
      await this.gameLoad()
    },
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  methods: {
    selectCategory (text, tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab
      } else {
        // this.currentTab = ''
      }
      this.categoryName = text
    },
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image) {
          return image
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets_mobile/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    slotOpen (event, groupCode, codeName) {
      console.log(event, groupCode, codeName)
      this.emitter.emit(event, { groupCode, codeName })
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = {
              ...item,
              bannerContent: item.bannerContent.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;')
            }
          }
        }
      })
      console.log(this.popupList)
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    onChangeType (type) {
      this.type = type
    },
    gameLoad () {
      this.loadMain()
      this.loadNotice()
      this.loadCashStatus()

      const gameList = JSON.parse(JSON.stringify(this.commonCodeByCode))

      for (const item in gameList) {
        const game = gameList[item]
        let type = 'type2'
        if (game.groupCode === 'casino') {
          type = 'tab-2'
          if (game.groupCodeName.indexOf('HC-') > -1) {
            type = 'tab-3'
          }
        } else if (game.groupCode === 'slot') {
          type = 'tab-4'
          if (game.groupCodeName.indexOf('H-') > -1) {
            type = 'tab-7'
          }
        } else if (game.groupCode === 'minigame') {
          type = 'tab-6'
        } else if (game.groupCode === 'sport') {
          type = 'tab-5'
        }
        game.type = type
        this.gameList.push(game)
      }

      this.loaded = true

      if (this.$route.params.prodId && this.$route.params.type) {
        if (this.userData && this.userData.kplayId) {
          const params = {
            kplayId: this.userData.kplayId,
            gameCode: this.$route.params.prodId,
            gameGroupCode: 'slot',
            type: this.$route.params.type,
            prodId: this.$route.params.prodId
          }
          getSlotGame(params)
            .then(response => {
              const result = response.data
              if (result.resultCode === '0') {
                const data = result.data
                const gameUrl = data.gameurl

                const _width = 1100
                const _height = 680
                const _left = Math.ceil((window.screen.width - _width) / 2)
                const _top = 100
                if (isMobile()) {
                  // mobile
                  const p = window.open('/', '_blank')
                  p.location.href = gameUrl
                } else {
                  // pc
                  window.open(gameUrl, 'evolution', `width=${_width},height=${_height},left=${_left},top=${_top}`)
                }
              }
            })
        } else {
          this.onCheck('front.error.afterSignin')
        }
      }
    }
  },
  async created () {
    await this.loadMain()
    await this.loadNotice()
    await this.loadCashStatus()
    this.gameLoad()
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    next()
  }
}
</script>
<style scoped>
.loading {z-index: 10;position: relative;width: 100%;height: 78px;display:flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.mainPop {position: relative;min-width: 320px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background: #fff;border: 1px solid #1c594e;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 320px;height: 440px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style>
.mainSlide img{width:100%;height: 200px;object-fit: cover;object-position: center;}
.swiper-button-next {background-image: url('~@/assets/img/slideright.png') !important;background-size: 11px 20px !important;}
.swiper-button-prev {background-image: url('~@/assets/img/slideleft.png') !important;background-size: 11px 20px !important;}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/main.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
